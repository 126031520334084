<template>
  <b-card no-body class="card-developer-meetup">
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h6 class="mb-0">THU</h6>
          <h3 class="mb-0">24</h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ $t("fields.birthdays") }}
          </b-card-title>
          <b-card-text class="mb-0">
            <!-- Meet world popular developers -->
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <div v-if="dashboard.length > 0">
        <b-media
          v-for="(item, index) in dashboard"
          :key="index + 'dob'"
          no-body
        >
          <router-link
            :to="
              item.module == 'familymembers'
                ? '/settings/familymembers/view/' + item.id
                : '/settings/children/view/' + item.id
            "
          >
            <b-media-body>
              <h6 class="mb-0">
                {{ item.data ? item.data.name : "" }}
              </h6>
              <small
                >{{ $t("fields.birth") }} :
                {{ item.data ? item.data.dob : "" }}</small
              >
            </b-media-body>
          </router-link>
        </b-media>
      </div>
      <div
        v-else
        class="justify-content-center w-100 text-center align-middle h-100"
      >
        <div
          class="row justify-content-center w-100 text-center align-middle align-items-center h-100"
        >
          <div class="col-12">
            <img
              :src="require('@/assets/images/custom/nodata.png')"
              alt=""
              class="w-100"
            />
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    dashboard: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
